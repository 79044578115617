
.month-selector {
  border-radius: 4px;
  border: 1px solid var(--zc-secondary-color-light);
  padding: 0.5rem;
}

.month-selector .years, .month-selector .months {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.month-selector .item {
  flex: 1 0 calc(33% - 0.5rem);
  padding: 0.5rem;
  margin: auto;
}

.month-selector .item.disabled {
  color: var(--zc-secondary-color-lighter);
  cursor: not-allowed;
}

.month-selector .item:hover:not(.disabled):not(.year) {
  background-color: var(--zc-secondary-color);
  color: #FFF;
}