.detail-table, .summary-table {
  border-width: 1px;
  border-style: solid;
}
.detail-table thead tr th {
  border-width: 1px;
}

.detail-table tbody tr td {
  border-width: 1px 1px;
}

.summary-table {
  width: 66.6666%;
}

.summary-table tbody tr th {
  border-width: 1px 1px;
}

.summary-table tbody tr td {
  border-width: 1px 1px;
}

.summary-table tbody tr * {
  width: 50%;
}
