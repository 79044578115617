.skeleton {
  background-color: var(--zc-secondary-color-light);
  border-radius: 4px;
  margin-bottom: .5rem;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, transparent, var(--zc-secondary-color-lighter), transparent);
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}

.skeleton-text {
  width: 100%;
  height: 1rem;
}
