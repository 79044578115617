.recharts-tooltip-wrapper .custom-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
.recharts-tooltip-wrapper .custom-tooltip .label {
  margin-bottom: 0.5rem;
}
.recharts-tooltip-wrapper .custom-tooltip p {
  margin: 0;
}

@media (max-width: 992px) {
  .recharts-label-list, .recharts-cartesian-axis-tick-value {
    font-size: .7rem;
  }
}

@media (max-width: 1200px) {
  .recharts-label-list, .recharts-cartesian-axis-tick-value {
    font-size: .8rem;
  }
}